import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace FilesAPI {
  export class GetFileByGuid implements APIRequest<string> {
    response: string;
    path = '/files/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (guid: string) {
      this.path += guid
    }
  }
}
